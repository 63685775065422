import React, { useEffect, useState } from "react";
import { Button, Link, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import SideBar from "../../components/SideBar";
import { PlusIcon } from "../../components/utils/InsyncIconsUtil";
import { InfoText, SubtitleM, SubtitleS } from "../../components/Typography";
import usePostRequest from "../../hooks/usePostRequest";

export default function IntegrationSidebar({
  system,
  integrations = [],
  environment = "staging",
}) {
  const user = useSelector((state) => state.user.value);
  const [config, setConfig] = useState(() => {
    const initialSettings = system?.integration_settings?.reduce(
      (acc, integration) => {
        const matchedIntegration = integrations.find(
          (item) => item.usecase === integration.usecase
        );

        acc[integration.usecase] = Object.keys(
          integration.settings_json
        ).reduce((innerAcc, key) => {
          innerAcc[key] = matchedIntegration?.settings_json[key] || "";
          return innerAcc;
        }, {});

        return acc;
      },
      {}
    );

    return initialSettings;
  });

  const [disabled, setDisaled] = useState(true);

  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const tokens = useSelector((state) => state?.tokens?.value);

  const handleChange = (key, value, usecase) => {
    let newSettings = { ...config };
    newSettings[usecase] = {
      ...newSettings[usecase],
      [key]: value,
    };
    setConfig(newSettings);
  };

  const { sendPostRequest } = usePostRequest(
    `/v1/apps/${currentApp.id}/integrations/upsert`,
    tokens
  );

  const handleSaveClick = async () => {
    try {
      let useCases = Object.keys(config);
      let newIntegrations = useCases.map((el) => {
        return {
          name: system.name,
          environment: environment,
          usecase: el,
          settings_json: config[el],
          external_system: system.external_system,
        };
      });

      const response = await sendPostRequest({ integrations: newIntegrations });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    let requiredKeys = system?.integration_settings?.some((element) => {
      return Object.keys(element.settings_json).some((key) => {
        return config[element.usecase][key] !== "";
      });
    });

    setDisaled(!requiredKeys);
  }, [config]);

  return (
    <SideBar
      key={system.name + "-drawer"}
      button={<PlusIcon />}
      buttonSx={{
        border: "1px solid #D8DCE2",
        maxWidth: "36px",
        minWidth: "36px",
        borderRadius: "5px",
      }}
      title={`Link Your ${system.name}`}
      footer={
        <div className="flex-row flex-justify-content-space-between flex-align-items-center full-width padding-left-24px padding-right-24px margin-top-8px margin-bottom-10px">
          <Link
            href={system.integration_guide}
            target="_blank"
            rel="noreferrer"
          >
            Integration Guide
          </Link>
          <Button
            disabled={disabled}
            variant="PrimaryButtonBlue"
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="flex-column">
        {system?.integration_settings?.map((integration) => (
          <div
            key={`integration-${integration.usecase}`}
            className={`flex-column margin-bottom-40px`}
          >
            <SubtitleM>{`${integration.usecase} Integration`}</SubtitleM>
            <div style={{ border: "1px solid #D8DCE2", height: "2px" }} />
            {integration?.settings_json &&
              Object.entries(integration?.settings_json).map(
                ([integrationKey, value]) => {
                  return (
                    <div
                      key={integrationKey}
                      className={`flex-column flex-justify-content-start flex-align-items-start`}
                    >
                      <SubtitleS style={{ color: "#000" }}>
                        {value.title}
                        {value.required && "*"}
                      </SubtitleS>
                      <TextField
                        required={value.required}
                        placeholder={value.placeholder}
                        defaultValue={
                          config[integration.usecase][integrationKey] || ""
                        }
                        onChange={(e) => {
                          handleChange(
                            integrationKey,
                            e.target.value,
                            integration.usecase
                          );
                        }}
                      />
                      <InfoText>{value.info}</InfoText>
                    </div>
                  );
                }
              )}
          </div>
        ))}
      </div>
    </SideBar>
  );
}
