import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { apiCall } from "../../api";
import { LabelM, PageHeader, SubtitleM } from "../../components/Typography";
import { getSubdomainAndMainDomain } from "../../utils";
import FileUpload from "../../components/FileUpload/FileUpload";
import { CLIENT_TYPES } from "../../constants/Constants";
import SwitchButton from "../../components/SwitchButton";

const COUNTRIES = ["", "US", "UK", "EU", "IN"];

export default function ManageClient(props) {
  const tokens = useSelector((state) => state?.tokens.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const isFirstRender = useRef(true);
  const initialState = {
    client_name: "",
    primary_website: "",
    logo_url: "",
    country: "",
    market: "",
    project_url: "",
    notes: "",
    url_code: "",
    copy_market: "",
    onboard_existing_client: true,
  };
  const [appData, setAppData] = useState(
    props.edit ? currentApp : initialState
  );

  const handleChange = (e, key) => {
    let value = key === "logo_url" ? e.target.value[0] : e.target.value;
    let newObj = { ...appData, [key]: value };

    if (key === "primary_website") {
      newObj.url_code = getSubdomainAndMainDomain(value);
    }
    setAppData((prev) => ({ ...prev, ...newObj }));
  };

  const handleLogoChange = (e) => {
    handleChange(e, "logo_url");
  };

  const handleSave = async (appData) => {
    const requestData = {
      client_name: appData.client_name,
      primary_website: appData.primary_website,
      notes: appData.notes,
      url_code: appData.url_code,
      project_url: appData.project_url,
      market: `${appData.country.toLowerCase()}_${appData.url_code}`,
      logo_url: appData.logo_url,
      country: appData.country,
      copy_market: appData.copy_market,
      onboard_existing_client: appData.onboard_existing_client,
    };

    let url = "/v1/apps" + (props.edit ? `/${currentApp.id}` : "");
    let appResponse = await apiCall(url, {
      method: props.edit ? "put" : "post",
      authTokens: tokens,
      body: requestData,
    });

    if (!props.edit && appResponse) {
      window.location.reload();
      window.location.href = `/${appData.url_code}/knowledge/websites`;
    }
  };

  // function to handle the switch toggle.
  const handleSwitchChange = (event) => {
    setAppData((prev) => ({
      ...prev,
      onboard_existing_client: event.target.checked,
    }));
  };

  const debouncedHandleSave = useCallback(debounce(handleSave, 2000), []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (props.edit) {
        debouncedHandleSave(appData);
        return () => {
          debouncedHandleSave.cancel();
        };
      }
    }
  }, [appData, debouncedHandleSave]);

  return (
    <>
      <PageHeader>
        {props.edit ? `Welcome ${appData.client_name}` : "OnBoard Client"}
      </PageHeader>

      <div
        style={{
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!props.edit && (
          <SubtitleM>
            Just need a few details of the client to start building the chat
            bot.
          </SubtitleM>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            Client Name
          </LabelM>
          <TextField
            margin="dense"
            onChange={(e) => handleChange(e, "client_name")}
            value={appData.client_name}
            aria-label="Client Name"
            placeholder="Enter Company Name"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            Country/Region
          </LabelM>
          <Autocomplete
            margin="dense"
            options={COUNTRIES}
            onChange={(_e, val) => {
              let obj = { target: { value: val || "" } };
              handleChange(obj, "country");
            }}
            disabled={props.edit}
            inputValue={appData.country}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            aria-label="Country"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            {"Client's Primary Website"}
          </LabelM>
          <TextField
            margin="dense"
            onChange={(e) => handleChange(e, "primary_website")}
            value={appData.primary_website}
            aria-label="Client's Primary Website"
            placeholder="Enter Website URL"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            {"Client's Code"}
          </LabelM>
          <TextField
            margin="dense"
            disabled
            value={appData.url_code}
            aria-label="Client's Code"
            placeholder="Client Code"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            {"Client's PROD App ID"}
          </LabelM>
          <TextField
            margin="dense"
            disabled
            value={appData.insync_prod_app_id}
            aria-label="Client's PROD App ID"
            placeholder="Client's PROD App ID"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            {"Client's Logo"}
          </LabelM>
          <div className="flex-row">
            {appData.logo_url && (
              <img height={"36.5px"} width={"36.5px"} src={appData.logo_url} />
            )}
            <FileUpload
              field={{
                name: "fileUpload",
                title: appData.logo_url ? "Change" : "+ Upload",
                multiple: false,
              }}
              accept=".svg,.img,.png"
              endpoint={"/v1/apps/logo_upload"}
              onChange={handleLogoChange}
            />
          </div>
          {!props.edit && (
            <>
              <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
                Client Type
              </LabelM>
              <Autocomplete
                margin="dense"
                options={CLIENT_TYPES}
                getOptionLabel={(option) => option.title}
                onChange={(e, val) => {
                  let obj = { target: { value: val?.value || "" } };
                  handleChange(obj, "copy_market");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                aria-label="Country"
              />
            </>
          )}
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            Project Url
          </LabelM>
          <TextField
            type="text"
            margin="dense"
            onChange={(e) => handleChange(e, "project_url")}
            value={appData.project_url}
            aria-label="project url"
            placeholder="Enter Project Url"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            Notes
          </LabelM>
          <TextField
            type="text"
            margin="dense"
            value={appData.notes}
            onChange={(e) => handleChange(e, "notes")}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "100px",
                maxHeight: "100px",
              },
            }}
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Enter Notes"
            aria-label="Market"
          />
          <LabelM style={{ marginTop: "20px", marginBottom: "10px" }}>
            Onboard Existing Client
          </LabelM>
          <SwitchButton
            handleChange={handleSwitchChange}
            checked={appData.onboard_existing_client}
            sx={{
              width: 50,
              height: 28,
              "& .MuiSwitch-thumb": {
                width: 25,
                height: 25,
                borderRadius: 16,
              },
              "& .MuiSwitch-track": {
                borderRadius: 16,
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(22px)",
              },
            }}
          />
        </div>
      </div>
      {!props.edit && (
        <footer
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingRight: "45px",
            paddingLeft: "0px",
            opacity: "0px",
            // height: "102px",
            // marginLeft: "-25px",
            marginTop: "35px",
            boxShadow: "0px -5px 10px 0px #0000000D",
            position: "fixed",
            bottom: 0,
            left: 0,
            background: "#fff",
            width: "100vw",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginRight: "32px",

              marginTop: 1,
            }}
          >
            <Button
              variant="SecondaryButton"
              LinkComponent={Link}
              sx={{ marginRight: "8px" }}
              to="/admin"
            >
              Cancel
            </Button>
            <Button variant="PrimaryButton" onClick={() => handleSave(appData)}>
              Create
            </Button>
          </Stack>
        </footer>
      )}
    </>
  );
}
