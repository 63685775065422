import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiCall } from "../../../api";
import SectionTopBar from "../../../components/SectionTopBar";
import Table from "../../../components/Table";
import { PageHeader } from "../../../components/Typography";
import OptionMenu from "../../../components/OptionMenu";
import UrlsSidebar from "./UrlsSidebar";
import usePostRequest from "../../../hooks/usePostRequest";
import TabularLoadingPlaceholder from "../../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import { convertUnderscoreToSpaces } from "../../../utils";

// Colour Codes for table columns
const ERROR_COLOR = "#ffcccc"; // When required field is not provided
const CHANGED_COLOR = "#f0f8ff"; // To show the updated fields
const INITIAL_COLOR = "initial";

export default function Websites() {
  const user = useSelector((state) => state.user.value);
  const tokens = useSelector((state) => state?.tokens.value);
  const params = useParams();
  const [appId, setAppId] = useState(0);
  const [websites, setWebsites] = useState([]);
  const [saved, setSaved] = useState(0);
  const [changes, setChanges] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  const { sendPostRequest, loading: updatingStatus } = usePostRequest(
    `/v1/apps/${currentApp?.id}/websites/`,
    tokens
  );

  const handleAddLinkClick = () => {
    const unfinished = websites.some((website) => website.url === "");
    if (!unfinished) {
      setWebsites((prev) => [
        {
          url: "",
          urls_count: "",
          site_map: "",
          status: "pending",
          enabled: "enabled",
          tags: "Add Tags +",
          user: user,
          app_id: appId,
        },
        ...prev,
      ]);
    } else {
      // Highlight the unfinished row or show a notification
    }
  };

  const handleChange = (index, key, value) => {
    setWebsites((prev) =>
      prev.map((row, i) => {
        if (i === index) {
          setChanges((c) => ({
            ...c,
            [index]: { ...c[index], [key]: true },
          }));
          return { ...row, [key]: value };
        }
        return row;
      })
    );

    // Clear validation error when user corrects the input
    setValidationErrors((prev) => ({
      ...prev,
      [index]: { ...prev[index], [key]: false },
    }));
  };

  // Check if required fields are present before making the request
  // and highlighting those on the UI
  const validateRows = () => {
    let hasErrors = false;
    const errors = {};

    websites.forEach((website, index) => {
      const errorFields = {};
      if (!website.url) {
        errorFields.url = true;
        hasErrors = true;
      }
      if (!website.site_map) {
        errorFields.site_map = true;
        hasErrors = true;
      }
      if (!website.status) {
        errorFields.status = true;
        hasErrors = true;
      }
      if (Object.keys(errorFields).length > 0) {
        errors[index] = errorFields;
      }
    });

    setValidationErrors(errors);
    return hasErrors;
  };

  // Called on SAVE button click
  const handleSave = async () => {
    if (validateRows()) {
      return;
    }

    const websitesToSave = websites
      .filter((website, index) => changes[index])
      .map((website) => ({
        ...website,
        app_id: currentApp.id,
      }));

    try {
      await apiCall(`/v1/apps/${currentApp.id}/websites/create_and_update`, {
        method: "POST",
        body: { websites: websitesToSave },
        authTokens: tokens,
      });

      setChanges({});
      setSaved((prev) => prev + 1);
    } catch (error) {
      console.error("Error saving websites:", error);
      //TODO: Show Error Alert once component is ready
    }
  };

  useEffect(() => {
    // TODO: Move this to useFetch
    const getLinks = async () => {
      try {
        let linksList = await apiCall(
          `/v1/apps/${currentApp.id}/websites?app_name=` + params.app_name,
          {
            method: "get",
            authTokens: tokens,
          }
        );
        setWebsites(linksList);
        if (linksList.length > 0) {
          setAppId(linksList[0].app_id);
        }
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching websites:", error);
      }
    };

    if (tokens && currentApp?.id) {
      getLinks();
    }
  }, [saved]);

  return (
    <>
      <PageHeader>Knowledge Pool: Websites</PageHeader>
      <SectionTopBar
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        <TextField
          placeholder="Search"
          margin="dense"
          sx={{
            width: "300px",
            maxHeight: "46px",
            margin: "0px",
            "& input": {
              padding: "10px 20px !important",
            },
          }}
        />
        <Button
          variant="PrimaryButtonBlue"
          onClick={handleAddLinkClick}
          // Disable button until data is loaded
          disabled={!dataLoaded}
        >
          + Add Website
        </Button>
      </SectionTopBar>

      {!dataLoaded || updatingStatus ? (
        <TabularLoadingPlaceholder numRows={9} numCols={8} />
      ) : (
        <Table
          customTableStyle={{
            width: "100%",
            marginTop: "30px",
          }}
          customStyle={{
            marginRight: "40px",
            overflowX: "visible",
          }}
          emptyText="Add URLs that your bot will refer to as its knowledge base"
          tableContents={websites?.map((website, websiteIndex) => {
            const changeSet = changes[websiteIndex] || {};
            const errorSet = validationErrors[websiteIndex] || {};
            return [
              {
                style: {
                  width: "300px !important",
                  backgroundColor: errorSet.url
                    ? ERROR_COLOR
                    : changeSet.url
                      ? CHANGED_COLOR
                      : INITIAL_COLOR,
                },
                id: (website.id ? website.id : "new") + "-url",
                element: {
                  type: "textfield",
                  value: website.url,
                  onChange: (e) =>
                    handleChange(websiteIndex, "url", e.target.value),
                  label: "website-url",
                  placeholder: "Enter Url",
                },
              },
              {
                id: (website.id ? website.id : "new") + "-urls_count",
                style: {
                  backgroundColor: changeSet.urls_count
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: {
                  value: website.urls_count?.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  }),
                },
              },
              {
                id: (website.id ? website.id : "new") + "-sitemap",
                style: {
                  backgroundColor: errorSet.site_map
                    ? ERROR_COLOR
                    : changeSet.site_map
                      ? CHANGED_COLOR
                      : INITIAL_COLOR,
                },
                element: {
                  type: "selector",
                  items: [
                    {
                      title: "Full Website",
                      value: "full_website",
                    },
                    {
                      title: "Just URL",
                      value: "just_url",
                    },
                  ],
                  value: website.site_map,
                  setValue: (val) =>
                    handleChange(websiteIndex, "site_map", val),
                },
              },
              {
                id: (website.id ? website.id : "new") + "-status",
                style: {
                  backgroundColor: errorSet.status
                    ? ERROR_COLOR
                    : changeSet.status
                      ? CHANGED_COLOR
                      : INITIAL_COLOR,
                },
                element: {
                  value: convertUnderscoreToSpaces(website.status),
                },
              },
              {
                id: (website.id ? website.id : "new") + "-tags",
                style: {
                  backgroundColor: changeSet.tags
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: {
                  type: "tags",
                  options: ["Development", "Staged", "Production"],
                  value: [],
                },
              },
              {
                id: (website.id ? website.id : "new") + "-user",
                style: {
                  backgroundColor: changeSet.user
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: { value: website.user.name },
              },
              {
                id: (website.id ? website.id : "new") + "-options",
                element: {
                  value: (
                    <OptionMenu
                      key={
                        (website.id ? website.id : "new") + "-options-button"
                      }
                      options={[
                        {
                          value: "view-links",
                          title: (
                            <UrlsSidebar
                              website={website}
                              currentApp={currentApp}
                              tokens={tokens}
                            />
                          ),
                        },
                        {
                          value: "index-all",
                          title: "Index All",
                          onClick: () => {
                            sendPostRequest(
                              { status: "to_be_indexed" },
                              `${website.id}/urls/update_status`
                            );
                          },
                        },
                        {
                          value: "skip-all",
                          title: "Skip All",
                          onClick: () => {
                            sendPostRequest(
                              { status: "to_be_skipped" },
                              `${website.id}/urls/update_status`
                            );
                          },
                        },
                        {
                          value: "delete",
                          title: "Delete URLs",
                          onClick: () => {
                            sendPostRequest(
                              { status: "to_be_deleted" },
                              `${website.id}/urls/update_status`
                            );
                          },
                        },
                      ]}
                    />
                  ),
                },
              },
            ];
          })}
          rowHeaders={[
            {
              columnData: "Urls",
              id: "urls",
            },
            {
              columnData: "Links",
              id: "links",
            },
            {
              columnData: "Scan Mode",
              id: "scan-mode",
            },
            {
              columnData: "Status",
              id: "status",
            },
            {
              columnData: "Data Tags",
              id: "data-tags",
            },
            {
              columnData: "Added By",
              id: "added-by",
            },
            {
              columnData: "",
              id: "options-menu",
            },
          ]}
        />
      )}

      {/* Sticky Footer */}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          padding: "10px",
          boxShadow: "0px -5px 10px 0px #0000000D",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!Object.keys(changes).some((index) => changes[index])}
        >
          Save
        </Button>
      </div>
    </>
  );
}
