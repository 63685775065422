import React, { useEffect, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import SideBar from "../../../components/SideBar";
import useFetch from "../../../hooks/useFetch";
import Table from "../../../components/Table";
import SwitchButton from "../../../components/SwitchButton";
import TabularLoadingPlaceholder from "../../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import { SubtitleS } from "../../../components/Typography";
import usePutRequest from "../../../hooks/usePutRequest";
import usePostRequest from "../../../hooks/usePostRequest";
import {
  convertUnderscoreToSpaces,
  getStringParamsFromObject,
} from "../../../utils";
import SearchBar from "../../../components/SearchBar";
import { FilterButton } from "../../../components/utils/InsyncIconsUtil";
import OptionMenu from "../../../components/OptionMenu";

const FILTER_OPTIONS = [
  "pending",
  "to_be_indexed",
  "sent_for_indexing",
  "to_be_deleted",
  "sent_for_deletion",
  "deleted",
  "indexed",
  "failed",
  "failed_to_delete",
  "skipped",
  "send_for_skipping",
  "failed_to_skip",
  "to_be_skipped",
  "to_be_refreshed",
].map((el) => {
  return {
    title: convertUnderscoreToSpaces(el),
    value: el,
    selected: false,
    type: "status",
  };
});

export default function UrlsSidebar({ website, currentApp, tokens }) {
  const [urls, setUrls] = useState({});
  const [statusFilters, setStatusFilters] = useState(
    FILTER_OPTIONS.map((opt, index) => {
      return {
        ...opt,
        onClick: () => {
          setStatusFilters((prev) => {
            let newArr = [...prev];
            newArr[index].selected = !newArr[index].selected;
            return newArr;
          });
        },
      };
    })
  );
  const [searchedUrls, setSearchedUrls] = useState({});
  const [isAddLinkBoxOpen, setIsAddLinkBoxOpen] = useState(false);
  const [addLinkBoxContent, setAddLinkBoxContent] = useState("");
  const [search, setSearch] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    page_size: 25,
    page: 0,
    order_by: "url",
    order: "asc",
  });

  const {
    data: urlData,
    loading,
    fetchData,
  } = useFetch(
    `/v1/apps/${currentApp.id}/websites/${website.id}/urls`,
    tokens,
    [],
    [],
    false
  );

  const { sendPutRequest } = usePutRequest(
    `/v1/apps/${currentApp.id}/websites`,
    tokens
  );

  const { sendPostRequest, loading: insertingUrls } = usePostRequest(
    `/v1/apps/${currentApp.id}/websites/${website.id}/urls/bulk_create`,
    tokens
  );

  const updateUrl = (updatedUrl) => {
    let newUrls = urls.data;
    let urlIndex = newUrls.findIndex((el) => el.id === updatedUrl?.id);
    if (urlIndex > -1) {
      newUrls[urlIndex] = updatedUrl;
      setUrls({ ...urls, data: newUrls });
    }
  };

  const onChangeRowsPerPage = (value) => {
    setPaginationParams({ ...paginationParams, page_size: value });
  };

  const onChangePage = (newPage) => {
    setPaginationParams({
      ...paginationParams,
      page: newPage,
    });
  };

  useEffect(() => {
    let url = `/v1/apps/${currentApp.id}/websites/${
      website.id
    }/urls?${getStringParamsFromObject({ ...paginationParams, search })}`;

    let selectedStatusFilters = statusFilters
      .filter((s) => s.selected)
      .map((s) => s.value)
      .join(",");
    console.log("first", selectedStatusFilters);
    if (!isEmpty(selectedStatusFilters)) {
      url += "&status=" + selectedStatusFilters;
    }
    fetchData(url);
  }, [paginationParams, statusFilters]);

  useEffect(() => {
    if (urlData) {
      setUrls(urlData);
    }
  }, [urlData]);

  const InfoSubtitle = (key, value) => {
    return (
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        paddingBottom={"0px"}
        sx={{
          height: "19px",
        }}
      >
        <SubtitleS
          style={{
            color: "#000",
          }}
        >
          {key}
        </SubtitleS>
        <SubtitleS
          style={{
            color: "#000",
          }}
        >
          {value}
        </SubtitleS>
      </Stack>
    );
  };

  return (
    <SideBar
      key={(website.id ? website.id : "new") + "-options-view-links-drawer"}
      button="View Links"
      buttonSx={{ padding: 0, color: "#000" }}
      onButtonClick={() => {
        fetchData(
          `/v1/apps/${currentApp.id}/websites/${
            website.id
          }/urls?${getStringParamsFromObject({ ...paginationParams, search })}`
        );
      }}
      title={website.url}
    >
      <Stack
        flexDirection={"column"}
        sx={{ width: "500px", marginBottom: "24px" }}
      >
        {InfoSubtitle("URL", website.url)}
        {InfoSubtitle(
          "Links",
          urls?.total?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        )}
        {InfoSubtitle("Last Updated", website.updated_at)}
      </Stack>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "500px", marginBottom: "24px" }}
      >
        <SearchBar
          data={urls}
          onSearchResults={(filteredData) => {
            setSearchedUrls(filteredData);
            if (!isEmpty(filteredData)) {
              setPaginationParams({
                page_size: filteredData?.page_size,
                page: filteredData?.page,
                order_by: filteredData?.order_by,
                order: filteredData?.order,
              });
            }
          }}
          onChange={(value) => {
            setSearch(value);
          }}
          triggerOn="onEnter"
          url={`/v1/apps/${currentApp.id}/websites/${website.id}/urls`}
          queryParam={`${getStringParamsFromObject(paginationParams)}&search`}
          searchKeys={["url"]}
        />

        <OptionMenu
          title={"Status"}
          enableCheckBoxes={true}
          zIndex={21474836}
          options={statusFilters}
          button={
            <Button
              title="Add Links"
              variant="SecondaryButton"
              sx={{
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <FilterButton />
              {statusFilters.some((el) => el.selected) && (
                <span className="selected-filters-span">
                  {statusFilters.filter((el) => el.selected).length}
                </span>
              )}
            </Button>
          }
        />

        <Button
          title="Add Links"
          variant="LightBlueBGButton"
          onClick={() => {
            setIsAddLinkBoxOpen(true);
          }}
        >
          + Links
        </Button>
      </Stack>
      {isAddLinkBoxOpen && (
        <Stack
          flexDirection={"column"}
          key="input-add-links"
          sx={{
            width: "500px",
            marginBottom: "24px",
            borderRadius: "10px 10px 10px 10px",
            border: "1px solid #9DA6B6",
          }}
        >
          <TextField
            type="text"
            disabled={insertingUrls}
            margin="dense"
            name={"Add Links"}
            key={`Add Links Text Box-${website.id}`}
            defaultValue={addLinkBoxContent}
            onChange={(e) => {
              setAddLinkBoxContent(e.target.value);
            }}
            sx={{
              width: "99%",
              "& .MuiInputBase-input": {
                padding: "25px 20px 25px 20px",
                border: "#fff",
              },
            }}
            multiline
            minRows={4}
            maxRows={4}
            placeholder={"Add Links"}
            aria-label={"Add Links"}
          />
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            paddingBottom={"0px"}
            sx={{ margin: "20px" }}
          >
            <Button
              onClick={(e) => {
                setIsAddLinkBoxOpen(false);
              }}
              sx={{ marginRight: "8px" }}
              variant="SecondaryButton"
            >
              Cancel
            </Button>
            <Button
              variant="PrimaryButtonBlue"
              disabled={isEmpty(addLinkBoxContent)}
              onClick={async (e) => {
                const response = await sendPostRequest({
                  urls: addLinkBoxContent.split("\n").map((e) => e.trim()),
                });
                if (response.message === "Ok") {
                  fetchData(
                    `/v1/apps/${currentApp.id}/websites/${
                      website.id
                    }/urls?${getStringParamsFromObject({
                      ...paginationParams,
                      search: search,
                    })}`
                  );
                }
              }}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      )}
      {loading ? (
        <TabularLoadingPlaceholder numRows={3} numColumns={3} />
      ) : (
        <Table
          customTableStyle={{
            width: "100%",
          }}
          customStyle={{
            // marginRight: "40px",
            overflowX: "visible",
          }}
          tableContents={(searchedUrls?.data || urls?.data)?.map((url, i) => [
            {
              id: url.url + url.id,
              element: { value: url.url },
            },
            {
              id: "status" + url.id,
              element: { value: convertUnderscoreToSpaces(url.status) },
            },
            {
              id: "skip" + url.id,
              element: {
                value: (
                  <SwitchButton
                    handleChange={async (e) => {
                      try {
                        const response = await sendPutRequest(
                          {
                            status: e.target.checked
                              ? "to_be_skipped"
                              : "to_be_indexed",
                          },
                          `/${website.id}/urls/${url.id}`
                        );
                        if (response) {
                          updateUrl(response);
                        }
                      } catch (error) {
                        console.error("Error saving data:", error);
                      }
                    }}
                    checked={[
                      "to_be_skipped",
                      "skipped",
                      "send_for_skipping",
                    ].includes(url.status)}
                  />
                ),
              },
            },
          ])}
          rowHeaders={[
            { id: "urls", columnData: "URLS" },
            {
              id: "status",
              columnData: "Status",
            },
            {
              id: "Skip",
              columnData: "Skip",
            },
          ]}
          pageNumber={paginationParams.page}
          rowsPerPageProp={paginationParams.page_size}
          rowsPerPageOptions={[1, 10, 25, 50, 100, 500, 1000, 2000]}
          pagination
          rowCount={urls.total}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          serverSidePagination
        />
      )}
    </SideBar>
  );
}
