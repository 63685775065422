import { useState } from "react";
import { apiCall } from "../api";

/**
 * Custom hook to handle POST requests.
 *
 * @param {string} url - The API endpoint URL where the POST request will be sent.
 * @param {object} tokens - The authentication tokens for accessing the API.
 * @returns {object} - An object containing the response data, loading state, error state,
 * and a function to send the POST request.
 * @property {any} data - The response data from the API.
 * @property {boolean} loading - Whether the POST request is currently in progress.
 * @property {Error|null} error - Any error that occurred during the POST request.
 * @property {function} sendPostRequest - Function to send the POST request.
 */
const usePostRequest = (url, tokens) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Function to send a POST request to the given URL.
   *
   * @param {object} body - The request body to be sent with the POST request.
   * @returns {Promise<any>} - The response data from the API.
   * @throws {Error} - Throws an error if the POST request fails.
   */
  const sendPostRequest = async (body, urlsParam = "") => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiCall(url + urlsParam, {
        method: "POST",
        authTokens: tokens,
        body,
      });

      setData(response || []);
      setLoading(false);
      return response;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw err;
    }
  };

  return { data, loading, error, sendPostRequest };
};

export default usePostRequest;
