import { Checkbox, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { OptionsMenuIcon } from "./utils/InsyncIconsUtil";
import { SmallHeader } from "./Typography";

export default function OptionMenu({
  options,
  button = null,
  zIndex = 21474833, // increase this value if using inside sidebar
  enableCheckBoxes = false,
  title = null,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    options.map((option) => option.selected || false)
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clonedButton = button
    ? React.cloneElement(button, { onClick: handleClick })
    : null;

  const handleMenuItemClick = (index, option) => {
    if (typeof option.onClick === "function") {
      option.onClick();
    }
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = !updatedSelectedOptions[index];
    setSelectedOptions(updatedSelectedOptions);
  };

  return (
    <div>
      {clonedButton ? (
        clonedButton
      ) : (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <OptionsMenuIcon />
        </IconButton>
      )}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          root: {
            style: {
              zIndex: zIndex,
            },
          },
          paper: {
            style: {
              //   maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: zIndex,
            },
          },
        }}
      >
        {title && (
          <SmallHeader style={{ margin: "4px 10px" }}>{title}</SmallHeader>
        )}
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={selectedOptions[index]}
            onClick={(e) => {
              handleMenuItemClick(index, option);
            }}
            sx={{
              paddingLeft: enableCheckBoxes ? "0px" : "16px",
              paddingRight: enableCheckBoxes ? "0px" : "16px",
              paddingTop: enableCheckBoxes ? "0px" : "6px",
              paddingBottom: enableCheckBoxes ? "0px" : "6px",
            }}
          >
            {enableCheckBoxes && <Checkbox checked={selectedOptions[index]} />}{" "}
            <span>{option.title}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
